<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">TAX Calculator</h1>
             <TaxCalculator />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
import TaxCalculator from "@/components/TaxCalculator";
export default {
  name: "TaxCalculatorPage",
  components: {
    TaxCalculator,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
</style>