<template>
  <section>
    <v-form>
      <v-row>
        <v-col md="12">
          <v-row>
            <div class="width100">
              <v-label>Vehicle Type</v-label>
              <div class="flexBox">
                <v-select
                  :items="vehicleTypes"
                  v-model="vehicleType"
                  placeholder="Vehicle Type"
                  outlined
                  class="mt-1"
                ></v-select>
              </div>
            </div>
            <div class="width100">
              <v-label>Year of Manufacture</v-label>
              <div class="flexBox">
                <v-text-field
                  class="mt-1"
                  placeholder="Year of Manufacture"
                  outlined
                  v-model="amount"
                ></v-text-field>
              </div>
            </div>
            <div class="width100">
              <v-label>Engine CC</v-label>
              <div class="flexBox">
                <v-text-field
                  class="mt-1"
                  placeholder="Engine"
                  outlined
                  v-model="amount"
                ></v-text-field>
              </div>
            </div>
            <div class="width100">
              <v-label>Renewal from</v-label>
              <div class="flexBox">
                <v-text-field
                  class="mt-1"
                  placeholder="Renewal from"
                  outlined
                  v-model="amount"
                ></v-text-field>
              </div>
            </div>
            <div class="width100">
              <v-label>Renewal to</v-label>
              <div class="flexBox">
                <v-text-field
                  class="mt-1"
                  placeholder="Renewal to"
                  outlined
                  v-model="amount"
                ></v-text-field>
              </div>
            </div>
            <div class="width100">
              <v-label></v-label>
              <div class="flexBox">
                <v-checkbox
                  class="mt-1"
                  placeholder="Renewal to"
                  outlined
                  label="Third Party Insurance"
                  v-model="amount"
                ></v-checkbox>
              </div>
            </div>
             <div class="width100">
               <v-btn large>Calculate</v-btn>
             </div>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
export default {
  name: "TaxCalculator",
  data: () => ({
    amount: 100000,
    downpayment: 0,
    interestRate: 10,
    loanTenure: 12,
    loanAmount: 0,
    totalAmount: 0,
    loanPer: "month",
    emi: {},
    vehicleTypes: ["2 Wheeler", "4 Wheeler"],
    vehicleType: "2 Wheeler",
  }),
  methods: {
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    loadCalculator() {
      if (this.downpayment == "") {
        this.downpayment = 0;
      }
      if (this.amount == "") {
        this.amount = 100000;
      }
      if (this.interestRate == "") {
        this.interestRate = 0;
      }
      if (this.loanTenure == "") {
        if (this.loanPer == "year") {
          this.loanTenure = 1;
        } else {
          this.loanTenure = 12;
        }
      }

      this.amount = parseFloat(this.amount);
      this.downpayment = parseFloat(this.downpayment);
      this.interestRate = parseFloat(this.interestRate);
      this.loanTenure = parseFloat(this.loanTenure);
      let loanYear =
        this.loanPer == "year" ? this.loanTenure * 12 : this.loanTenure;
      const interest = this.interestRate / 1200;
      let term = loanYear;
      let top = Math.pow(1 + interest, term);
      let bottom = top - 1;
      let ratio = top / bottom;
      this.loanAmount = (this.amount - this.downpayment) * interest * ratio;
      this.totalAmount = this.loanAmount * term;
    },
  },
  created() {
    this.loadCalculator();
  },
};
</script>
<style lang="scss" scoped>
.width100 {
  width: 100%;
  padding: 0 15px;
}
.flexBox {
  display: flex;
  ul {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 4px;
    li {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #9b9a9a;
      font-size: 18px;
      cursor: pointer;
      &.active {
        background: rgb(226, 231, 226);
      }
    }
  }
}
.v-text-field {
  border-radius: 0;
}
.totalSection {
  list-style: none;
  padding-left: 0;
  li {
    font-size: 18px;
    font-weight: 600;
    line-height: 35px;
    display: flex;
    justify-content: flex-start;
    span {
      display: block;
      margin-right: 5px;
    }
  }
}
.no-border {
  &-left {
    border-left: 0 !important;
  }
  &-right {
    border-right: 0 !important;
  }
}
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-flow: column;
}
</style>